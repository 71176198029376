import { render, staticRenderFns } from "./ConfirmComp.vue?vue&type=template&id=4fbcb3f0&scoped=true"
import script from "./ConfirmComp.vue?vue&type=script&lang=js"
export * from "./ConfirmComp.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/common/modal.css?vue&type=style&index=0&id=4fbcb3f0&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/modal/space/playstop.css?vue&type=style&index=1&id=4fbcb3f0&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fbcb3f0",
  null
  
)

export default component.exports